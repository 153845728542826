import React, { useState } from 'react';
import { Link, navigate } from 'gatsby';
import Logo from '../components/Logo';
import Footer from '../components/Footer';
import '../assets/scss/site.scss';
import { login, useAuthentication } from '../services/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Helmet } from 'react-helmet';

const Login = () => {
  const isAuthenticated = useAuthentication();
  const [usernameError, setUsernameError] = useState();
  const [passwordError, setPasswordError] = useState();

  if (isAuthenticated === null) {
    return null;
  }

  if (isAuthenticated === true) {
    navigate('/dashboard/');
    return <div />;
  }

  const onSubmit = e => {
    const formData = new FormData(e.target);
    login(formData.get('username'), formData.get('password'))
      .then(() => void navigate('/dashboard/'))
      .catch(err => {
        setUsernameError('');
        setPasswordError('');

        switch (err.code) {
          case 'auth/invalid-email':
            setUsernameError(err.message);
            break;
          case 'auth/user-not-found':
            setUsernameError(err.message);
            break;

          case 'auth/wrong-password':
            setPasswordError('The password is invalid');
            break;
        }
      });

    e.preventDefault();
  };

  return (
    <div className="page-content">
      <Helmet>
        <title>Login - Adshim Portal</title>
      </Helmet>

      <div className="content-wrapper">
        <div className="content d-flex justify-content-center align-items-center">
          <form className="login-form" onSubmit={onSubmit}>
            <div className="card mb-0">
              <div className="card-body">
                <div className="text-center mb-3">
                  <div className="text-primary border-primary btn bg-transparent rounded-round border-3 btn-icon mb-3 mt-1 p-3">
                    <Logo width="40px" height="40px" />
                  </div>
                  {/* <FontAwesomeIcon
                    icon={['fas', 'bacon']}
                    className="text-primary border-primary btn bg-transparent rounded-round border-3 btn-icon mb-3 mt-1 p-3"
                    style={{ width: '80px', height: '80px' }}
                  /> */}
                  <h5 className="mb-0">Login to your account</h5>
                  <span className="d-block text-muted">Enter your credentials below</span>
                </div>

                <div className="form-group form-group-feedback form-group-feedback-left">
                  <input
                    name="username"
                    type="text"
                    className="form-control"
                    placeholder="Username"
                  />
                  <div className="form-control-feedback">
                    <FontAwesomeIcon icon={['fas', 'user']} className="text-muted" />
                  </div>
                  {usernameError ? (
                    <label
                      id="username-error"
                      className="validation-invalid-label"
                      htmlFor="username"
                    >
                      {usernameError}
                    </label>
                  ) : null}
                </div>

                <div className="form-group form-group-feedback form-group-feedback-left">
                  <input
                    name="password"
                    type="password"
                    className="form-control"
                    placeholder="Password"
                  />
                  <div className="form-control-feedback">
                    <FontAwesomeIcon icon={['fas', 'lock']} className="text-muted" />
                  </div>
                  {passwordError ? (
                    <label
                      id="password-error"
                      className="validation-invalid-label"
                      htmlFor="password  "
                    >
                      {passwordError}
                    </label>
                  ) : null}
                </div>

                <div className="form-group">
                  <button type="submit" className="btn btn-primary btn-block">
                    Sign in <FontAwesomeIcon icon={['fas', 'sign-in-alt']} className="ml-2" />
                  </button>
                </div>

                <div className="text-center">
                  <Link to="/password-reset">Forgot password?</Link>
                </div>
              </div>
            </div>
          </form>
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default Login;
